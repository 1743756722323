import Axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import type { Account } from '@/app/account/account.types';
import type { UserRole, CreateableByClientObj } from '@/domain/user';
import { DEFAULT_LANGUAGE_KEY } from '@/constants/i18n';
// import { Auth } from '@/lib/amplify';
import { AppError } from '@/errors/AppError';

import type { SignUpParams } from '@aws-amplify/auth/lib-esm/types';
import type { ISignUpResult } from  'amazon-cognito-identity-js';

const accountKeys = {
  all: () => ['accountService'] as const,
  account: () => [...accountKeys.all(), 'account'] as const,
};

export const useAccount = (
  config: UseQueryOptions<
    Account,
    AxiosError,
    Account,
    InferQueryKey<typeof accountKeys.account>
  > = {}
) => {
  const { i18n } = useTranslation();
  const { data: account, ...rest } = useQuery(
    accountKeys.account(),
    async (): Promise<Account> => {
      let response: Account | null = null;
      try {
        response = await Axios.get<Account>('/account');
      } catch (error) {
        if ((error as any).message === 'Request failed with status code 401') {
          new AppError(`UserNotSignedIn`);
        } else {
          console.info(error);
        }
      }
      if (response === null) {
        new AppError(`UserNotSignedIn`);
      }
      return response!!;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // i18n.changeLanguage(data?.langKey);

        if (config?.onSuccess) {
          config?.onSuccess(data);
        }
      },
      retry: false,
      ...config,
    }
  );

  const isAdmin = !!account?.authorities?.includes('ADMIN') && account.activated;
  const isCompleted = !!account?.completed;
  const isEditor = isAdmin || !!account?.authorities?.includes('EDITOR') && account.activated;
  const hasRole = (role: UserRole) => !!account?.authorities?.includes(role) && account.activated;

  return { account, isAdmin, isEditor, isCompleted, hasRole, ...rest };
};

export const useCreateAccount = (
  config: UseMutationOptions<
    Account,
    AxiosError,
    CreateableByClientObj & { password: string }
  > = {}
) => {
  return useMutation(
    async ({
      sector = `ADMIN`,
      email,
      password,
      langKey = DEFAULT_LANGUAGE_KEY,
    }): Promise<Account> => {
      const credentials: SignUpParams = {
        username: email,
        password,
        attributes: {
          'custom:sector': sector,
          'custom:language': langKey,
        },
      };
      const { Auth } = await import(/* webpackChunkName: "lib_amplify" */ '@/lib/amplify');
      let response: ISignUpResult = await Auth.signUp({
        ...credentials,
        autoSignIn: {
          enabled: false,
        },
      });

      console.info(response);

      const account: Account = {
        email: response.user.getUsername(),
        activated: false,
      };

      return account;
    },
    {
      ...config,
    }
  );
};

type UseActiveAccountVariables = {
  key: string;
};

export const useActivateAccount = (
  config: UseMutationOptions<void, AxiosError, UseActiveAccountVariables> = {}
) => {
  return useMutation(
    ({ key }): Promise<void> => Axios.get(`/activate?key=${key}`),
    {
      ...config,
    }
  );
};

export const useUpdateAccount = (
  config: UseMutationOptions<Account, AxiosError, Account> = {}
) => {
  return useMutation(
    (account): Promise<Account> => {
      return Axios.post('/account', account);
    },
    {
      ...config,
      onMutate: (data) => {
        console.log('ON MUTATE');
        if (config?.onMutate) {
          config.onMutate(data);
        }
      },
      onSuccess: (...args) => {
        console.log('SUCCESS');
        config?.onSuccess?.(...args);
      },
    }
  );
};

export const useResetPasswordInit = (
  config: UseMutationOptions<void, AxiosError, string> = {}
) => {
  return useMutation(
    (email): Promise<void> =>
      Axios.post('/account/reset-password/init', email, {
        headers: { 'Content-Type': 'text/plain' },
      }),
    {
      ...config,
    }
  );
};

type UseResetPasswordFinishVariables = {
  key: string;
  newPassword: string;
};

export const useResetPasswordFinish = (
  config: UseMutationOptions<
    void,
    AxiosError,
    UseResetPasswordFinishVariables
  > = {}
) => {
  return useMutation(
    (payload): Promise<void> =>
      Axios.post('/account/reset-password/finish', payload),
    {
      ...config,
    }
  );
};

export function x() {
  console.log(x);
  return;
}

export const useUpdatePassword = (
  config: UseMutationOptions<
    void,
    AxiosError,
    { currentPassword: string; newPassword: string }
  > = {}
) => {
  return useMutation(
    async ({ currentPassword, newPassword }): Promise<void> => {

      // const re = /^[\\S]+.*[\\S]+$"/;

      console.log('Payload', currentPassword, newPassword);
      // Axios.post('/account/change-password', payload);
      try {
        const { Auth } = await import(/* webpackChunkName: "lib_amplify" */ '@/lib/amplify');
        const currentUser = await Auth.currentAuthenticatedUser();
        let result = await Auth.changePassword(currentUser, currentPassword, newPassword);
      console.log(result);
      } catch (error) {
        if ((error as TODO).code === 'InvalidParameterException') {
        }
        console.log("ERRR");
        console.log(error);
        throw error;
      }
      return;
    },
    {
      ...config,
    }
  );
};
