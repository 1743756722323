import { ReactNode, FC, useState } from 'react';

import {
  Button,
  Link as ChakraLink,
  HStack,
  forwardRef,
  useBreakpointValue,
} from '@chakra-ui/react';
import type { /* ChakraComponent, */ HTMLChakraProps } from '@chakra-ui/react';
import { Box, Collapse, Flex, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Link from 'next/link';

import { PATHNAMES, TOP_NAVIGATION } from '@/lib/routes';
import MenuButton from './MenuButton';
import { useAccount } from '@/app/account/account.service';

export interface StyledTheItemProps extends HTMLChakraProps<'button'> {}

const TheButton = forwardRef<StyledTheItemProps, 'button'>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <Button
      ref={ref}
      maxWidth="15em"
      style={{ boxShadow: 'rgba(0, 0, 0, 0.48) 0px 0px 0.5px 0.5px' }}
      rounded={'full'}
      variant={'@topMenuButon'}
      size={'sm'}
      border="1px solid rgba(255, 255, 255, 0.7)"
      _hover={{
        border: '1px solid #584bff',
        boxShadow: '0 0 0.5px 0.5px #584bff inset',
        bg: '#584bff',
      }}
      fontWeight={'semibold'}
      minW={'12em'}
      py={5}
      px={6}
      {...rest}
    >
      {children}
    </Button>
  );
});

const NavLinkTop = ({
  children,
  path,
}: {
  children: ReactNode;
  path: string;
}) => {
  // const router = useRouter();
  const isActive = false; // router.pathname === path;

  return (
    <ChakraLink
      fontWeight="semibold"
      fontSize="sm"
      href={path}
      mr={{ base: 1, md: 2, lg: 4, xl: 16 }}
      ml={4}
      pb={2}
      pt={3}
      px={1}
      lineHeight="none"
      borderBottom={`2px solid ${isActive ? '#ffffff' : 'transparent'}`}
      _hover={{
        textDecoration: 'none',
        borderBottom: '2px solid #ffffff',
      }}
      isExternal={false}
    >
      {children}
    </ChakraLink>
  );
};

const StyledBox = styled(Box)`{
  linear-gradient(
    90deg,
    rgba(106, 0, 241, 1) 0%,
    rgba(103, 0, 239, 1) 18%,
    rgba(88, 0, 231, 1) 35%,
    rgba(58, 0, 216, 1) 51%,
    rgba(30, 0, 202, 1) 72%,
    rgba(9, 0, 190, 1)
    100%
  );
}`;

const LandingHeader: FC<TODO> = ({ children, ...rest }) => {
  const [show, setShow] = useState(false);
  const isMenuButtonVisible = useBreakpointValue({ base: true, lg: false });
  const { account, isLoading, isAdmin } = useAccount();
  const isLogged = !!account && !isLoading;

  const handleToggleMenu = (event: Event) => {
    event.stopPropagation();
    setShow(!show);
  };

  return (
    <StyledBox w="100%" {...rest}>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        mb={0}
        w="100%"
        px={6}
        py={9}
        color={['white', 'white', 'white', 'white']}
      >
        <Flex align="center" flex="1">
          {children}
        </Flex>
        {!isMenuButtonVisible ? (
          <Flex justifyContent="flex-start" flex="1">
            {TOP_NAVIGATION.map((link, id) => (
              <NavLinkTop key={id} path={link.path}>
                {link.label}
              </NavLinkTop>
            ))}
            {isAdmin && (<NavLinkTop path={`${PATHNAMES.app}`}>
                Administrace
            </NavLinkTop>)}
            {!isLogged && (<Link href={`${PATHNAMES.publicLogin}?redirect=${encodeURIComponent('^/' + PATHNAMES.home)}`} prefetch={false} passHref>
              <TheButton ml={{ base: 1, md: 2, lg: 4, xl: 16 }}>
                Přihlásit
              </TheButton>
            </Link>)}
            {isLogged && (<Link href={`${PATHNAMES.publicLogout}?redirect=${encodeURIComponent('^/' + PATHNAMES.home)}`} prefetch={false} passHref>
              <TheButton ml={{ base: 1, md: 2, lg: 4, xl: 16 }}>
                Odhlásit
              </TheButton>
            </Link>)}
          </Flex>
        ) : null}
        <Box>
          {isMenuButtonVisible ? (
            <MenuButton
              isOpen={show}
              onClick={handleToggleMenu}
              strokeWidth="2"
              color="#fff"
              transition={{ ease: 'easeOut', duration: 0.2 }}
              width="32"
              height="12"
            />
          ) : null}
        </Box>
      </Flex>

      <Collapse in={show} animateOpacity>
        <Box
          py={8}
          px={4}
          display={{ lg: 'none' }}
          bgColor={'#4900cd'}
          color={'white'}
        >
          <Stack as={'nav'} spacing={4}>
            {TOP_NAVIGATION.map((link, id) => (
              <Box py={1} pl={6} key={id}>
                <NavLinkTop path={link.path}>{link.label}</NavLinkTop>
              </Box>
            ))}
            <HStack
              shouldWrapChildren={true}
              spacing={0}
              wrap="wrap"
              justifyContent="left"
              pl={6}
              pt={6}
            >
              {isAdmin && (<Link href={`${PATHNAMES.app}`} prefetch={false} passHref>
                <TheButton mb={6} mr={2}>
                  Administrace
                </TheButton>
              </Link>)}
              {!isLogged && (<Link href={`${PATHNAMES.publicLogin}?redirect=${encodeURIComponent('^/' + PATHNAMES.home)}`} prefetch={false} passHref>
                <TheButton mb={6}>
                  Přihlásit
                </TheButton>
              </Link>)}
              {isLogged && (<Link href={`${PATHNAMES.publicLogout}?redirect=${encodeURIComponent('^/' + PATHNAMES.home)}`} prefetch={false} passHref>
                <TheButton mb={6}>
                  Odhlásit
                </TheButton>
              </Link>)}
            </HStack>
          </Stack>
        </Box>
      </Collapse>
    </StyledBox>
  );
};

export default LandingHeader;
