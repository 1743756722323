import { forwardRef } from 'react';
import { chakra } from '@chakra-ui/react';

export const Logo = forwardRef<any, any>((props, ref) => {
  // const theme = useTheme();

  return (
    <chakra.svg h="2rem" viewBox="0 0 122.28 32.69" {...props} ref={ref} cursor="pointer">
      <g id="Group_2840" data-name="Group 2840" fill="currentColor">
        <g id="Group_2836" data-name="Group 2836">
          <path
            d="M899,535.24l1.45-1.47a3.06,3.06,0,0,0,2.31,1.12c1,0,1.3-.32,1.3-.84s-.26-.64-.88-.73l-1-.11c-2-.25-2.94-1.12-2.94-2.7s1.31-2.85,3.39-2.85a4.37,4.37,0,0,1,3.56,1.37l-1.42,1.44a2.93,2.93,0,0,0-2-.9c-.83,0-1.16.3-1.16.74s.2.71.91.81l1,.13c1.9.25,2.88,1.12,2.88,2.63,0,1.68-1.5,2.91-3.74,2.91A4.56,4.56,0,0,1,899,535.24Z"
            transform="translate(-899.03 -524.14)"
          />
          <path
            d="M908.13,536.6v-8.73h2.46v1.48h.1a2.33,2.33,0,0,1,2.35-1.68,2.39,2.39,0,0,1,2.44,1.76h0a2.73,2.73,0,0,1,2.67-1.76c1.68,0,2.61,1.26,2.61,3.46v5.47h-2.46v-5.27c0-1.13-.39-1.67-1.2-1.67a1.31,1.31,0,0,0-1.44,1.17.45.45,0,0,0,0,.11v5.66h-2.46v-5.27c0-1.13-.4-1.67-1.2-1.67a1.32,1.32,0,0,0-1.44,1.19.28.28,0,0,0,0,.09v5.66Z"
            transform="translate(-899.03 -524.14)"
          />
          <path
            d="M929.45,536.6a1.66,1.66,0,0,1-1.66-1.53h-.1a2.36,2.36,0,0,1-2.56,1.73,2.44,2.44,0,0,1-2.7-2.14,1.86,1.86,0,0,1,0-.41c0-1.84,1.43-2.72,3.74-2.72h1.38V531a1.26,1.26,0,0,0-1.45-1.38,2.17,2.17,0,0,0-1.88,1l-1.46-1.3a3.82,3.82,0,0,1,3.53-1.67c2.46,0,3.72,1.13,3.72,3.2v3.82h.82v1.91ZM927.54,534v-1h-1.2c-1,0-1.45.35-1.45,1v.25c0,.64.42.94,1.15.94s1.5-.34,1.5-1.14Z"
            transform="translate(-899.03 -524.14)"
          />
          <path
            d="M932.46,536.6v-8.73h2.47v1.9H935a2.19,2.19,0,0,1,2.23-1.9h.43v2.29h-.62c-1.43,0-2.13.32-2.13,1.33v5.11Z"
            transform="translate(-899.03 -524.14)"
          />
          <path
            d="M942.31,536.6a2.3,2.3,0,0,1-2.59-2,2.33,2.33,0,0,1,0-.59v-4.28h-1.23v-1.91h.61c.67,0,.87-.33.87-.93V525.5h2.21v2.36h1.73v1.91h-1.73v4.91h1.6v1.91Z"
            transform="translate(-899.03 -524.14)"
          />
          <path
            d="M949,532.22c0-2.79,1.44-4.55,4.07-4.55a3.41,3.41,0,0,1,3.49,2.28l-2,.86a1.44,1.44,0,0,0-1.5-1.2c-1,0-1.51.72-1.51,1.81v1.63c0,1.1.52,1.8,1.51,1.8a1.62,1.62,0,0,0,1.63-1.3l1.92.84a3.44,3.44,0,0,1-3.55,2.41C950.44,536.8,949,535,949,532.22Z"
            transform="translate(-899.03 -524.14)"
          />
          <path
            d="M957.94,525.72v-.36c0-.7.44-1.22,1.43-1.22s1.43.52,1.43,1.22v.36c0,.7-.43,1.22-1.43,1.22S957.94,526.42,957.94,525.72Zm.2,2.15h2.47v8.73h-2.47Z"
            transform="translate(-899.03 -524.14)"
          />
          <path
            d="M966,536.6a2.3,2.3,0,0,1-2.59-2,2.33,2.33,0,0,1,0-.59v-4.28h-1.23v-1.91h.62c.66,0,.86-.33.86-.93V525.5h2.22v2.36h1.73v1.91h-1.73v4.91h1.6v1.91Z"
            transform="translate(-899.03 -524.14)"
          />
          <path
            d="M974.61,527.87h2.29L973.54,538a2.48,2.48,0,0,1-2.67,2h-1.53V538H971l.3-1-3.06-9.18h2.45l1.28,4.07.63,2.53h.1l.65-2.53Z"
            transform="translate(-899.03 -524.14)"
          />
          <path
            d="M899.75,544.25h2.46v5.06h.1a2.38,2.38,0,0,1,2.43-1.68c1.78,0,2.73,1.26,2.73,3.46v5.47H905v-5.28c0-1.11-.32-1.66-1.22-1.66s-1.58.42-1.58,1.28v5.66h-2.46Z"
            transform="translate(-899.03 -524.14)"
          />
          <path
            d="M914.82,555.09h-.09a2.37,2.37,0,0,1-2.44,1.67c-1.78,0-2.73-1.25-2.73-3.45v-5.48H912v5.27c0,1.06.35,1.66,1.25,1.66a1.38,1.38,0,0,0,1.54-1.22v-5.71h2.46v8.73h-2.46Z"
            transform="translate(-899.03 -524.14)"
          />
          <path
            d="M919.57,544.25H922v5h.08a2.36,2.36,0,0,1,2.34-1.66c2.22,0,3.36,1.6,3.36,4.55s-1.14,4.58-3.36,4.58a2.39,2.39,0,0,1-2.34-1.67H922v1.47h-2.47Zm5.69,8.7v-1.52a1.64,1.64,0,0,0-1.46-1.8h-.15c-.93,0-1.61.48-1.61,1.28v2.58c0,.8.68,1.28,1.61,1.28a1.63,1.63,0,0,0,1.62-1.66.76.76,0,0,0,0-.15Z"
            transform="translate(-899.03 -524.14)"
          />
        </g>
        <g id="Group_2838" data-name="Group 2838">
          <g id="Group_2837" data-name="Group 2837">
            <rect
              id="Rectangle_38"
              data-name="Rectangle 38"
              x="98.66"
              y="2.48"
              width="15.19"
              height="6.47"
              rx="3.23"
            />
            <rect
              id="Rectangle_39"
              data-name="Rectangle 39"
              x="115.54"
              y="2.48"
              width="6.47"
              height="6.47"
              rx="3.23"
            />
          </g>
          <rect
            id="Rectangle_40"
            data-name="Rectangle 40"
            x="98.66"
            y="14.35"
            width="23.63"
            height="6.47"
            rx="3.23"
          />
          <rect
            id="Rectangle_41"
            data-name="Rectangle 41"
            x="98.66"
            y="26.22"
            width="15.19"
            height="6.47"
            rx="3.23"
          />
        </g>
      </g>
    </chakra.svg>
  );
});
